<template lang="pug">
.root
  .account-img(:style="{zIndex:zIndex,height:size,width:size}" @click="$emit('avatarClick')")
    img(v-show="validAttribute" class="frame" :src="frameImage" alt="")
    img(class="account" :src="avatarImage" alt="")
</template>

<script>
import { getImageUrl } from '@/utils/tools'
import { getFrameImage } from '@/utils/image'

export default {
  name: 'PanThumb',
  props: {
    // name:{
    //   type:String,
    //   required:true
    // },
    size:{
      type: String,
      default: '80px'
    },
    attribute:{
      type:String,
      required:false
    },
    avatar: {
      type: String,
      required: false
    },
    current_title: {
      type: String,
      required: false
    },
    isStreamer: {
      type: Boolean,
      required: false,
      default: true
    },
    zIndex: {
      type: Number,
      default: 1
    },
    width: {
      type: String,
      default: '80px'
    },
    height: {
      type: String,
      default: '80px'
    },
    frameWidth: {
      type: String,
      default: '82px'
    },
    frameHeight: {
      type: String,
      default: '82px'
    }
  },
  computed:{
    validAttribute(){
      if(this.attribute === ''){
        return false
      }
      return (this.attribute === 'a0'||this.attribute === 'a1'||this.attribute === 'a2'||this.attribute === 'a3'||this.attribute === 'a4')
    },
    avatarImage(){
      if (this.avatar?.length > 3){
        return getImageUrl(this.avatar)
      }else{
        return "/img/default_avatar.png"
      }
    },
    frameImage(){
      const attribute_list = this.$store.state.system.enum_list.attribute_list;
      return getFrameImage(this.current_title, this.attribute, attribute_list, this.isStreamer);
    }
  }
}
</script>


<style src="./Index.scss" lang="scss" scoped>
</style>
