import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import japanese from 'element-ui/lib/locale/lang/ja';

import scroll from 'vue-seamless-scroll';
Vue.use(scroll)

Vue.config.devtools = (process.env.VUE_APP_ENV === "DEV");
import preventReClick from '@/utils/preventReClick';
Vue.use(preventReClick);

Vue.config.productionTip = false;

import "vue-resize/dist/vue-resize.css";
import VueResize from "vue-resize";
Vue.use(VueResize);

Vue.use(require('vue-moment'));

import Toasted from 'vue-toasted';
const Options = { position: 'top-center', duration: 3000 }
Vue.use(Toasted, Options);

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

Vue.use(ElementUI, {locale: japanese})
Vue.config.devtools = true;

// Global Components
import ComponentFooterIndex from '@/components/Footer/index';
import ComponentFooterLogin from '@/components/Footer/login';
import ComponentHeaderIndex from '@/components/Header/index';
import ComponentHeaderLogin from '@/components/Header/login';
import ComponentTop from "@/components/Top/index.vue";
import PRBanner from '@/components/PRBanner'
import ComponentProfileIndex from '@/components/Profile/index';
import ComponentError from '@/components/Error/index';
import ComponentSystemMessage from "@/components/SystemMessage/index.vue";
Vue.component('component-footer-index', ComponentFooterIndex);
Vue.component('component-footer-login', ComponentFooterLogin);
Vue.component('component-header-index', ComponentHeaderIndex);
Vue.component('component-header-login', ComponentHeaderLogin);
Vue.component('component-top', ComponentTop);
Vue.component('pr-banner', PRBanner);
Vue.component('component-profile-index', ComponentProfileIndex);
Vue.component('component-error', ComponentError);
Vue.component('component-system-message', ComponentSystemMessage);

const messages = {
  en: {
    message: 'hello',
    attribute: {
      NONE: 'NONE',
      FIRE: 'FIRE',
      ICE: 'ICE',
      WIND: 'WIND',
      EARTH: 'EARTH'
    },
    eventType: {
      GENERAL: 'Genral',
      PRE_AUDITION: 'Preaudition',
      AUDITION: 'Audition'
    },
    importance: {
      LOW: 'Low',
      MIDDLE: 'Middle',
      HIGH: 'High'
    },
    conditionType:{
      CASH: 'Cash',
      COIN: 'Coin'
    }
  },
  ja: {
    message: 'こんにちは',
    attribute: {
      NONE: '無属性',
      FIRE: '火属性',
      ICE: '氷属性',
      WIND: '風属性',
      EARTH: '土属性'
    },
    eventType: {
      GENERAL: '通常',
      PRE_AUDITION: '準オーディション',
      AUDITION: 'オーディション'
    },
    importance: {
      LOW: '低',
      MIDDLE: '中',
      HIGH: '高'
    },
    conditionType:{
      CASH: 'キャッシュ',
      COIN: 'コイン'
    },
    // https://github.com/Laravel-Lang/lang/blob/master/locales/ja/validation.php
    validation: {
      required: '{attribute}が入力されていません',
      lt: {
        NUMERIC: '{attribute}は{value}より小さな値を指定してください',
        STRING: '{attribute}は{value}より短く指定してください',
        DATE: '{attribute}は{value}より前の日付を指定してください',
      },
      date_format: '{attribute}の形式は正しくありません。{format}のデータを選択してください',
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages // set locale messages
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
