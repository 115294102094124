import request from '@/utils/request'
import { isPrd } from '@/utils/tools'

export function getSystemInfo() {
  return request({
      url: `/system`,
      method: 'get',
  })
}

export function getUserule() {
  let url = "https://resource.a-live.tokyo/static_info/terms_of_use.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/terms_of_use.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getSystemTimeStamp(){
  return request({
    url: `/system?only_timestamp=true`,
    method: 'get',
  })
}

export function getManual() {
  let url = "https://resource.a-live.tokyo/static_info/operation_manual_streamer.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/operation_manual_streamer.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getStaticFundsTerms() {
  let url = "https://resource.a-live.tokyo/static_info/fund_terms_of_use.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/fund_terms_of_use.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getStaticPrivacyPolicy() {
  let url = "https://resource.a-live.tokyo/static_info/privacy_policy.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/privacy_policy.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getStaticTermOfUse() {
  let url = "https://resource.a-live.tokyo/static_info/terms_of_use.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/terms_of_use.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getStaticNotation() {
  let url = "https://resource.a-live.tokyo/static_info/notation.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/notation.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getEventNotice(){
  let url = "https://resource.a-live.tokyo/static_info/event_note.json"
  if( !isPrd() ){
    url = "https://resource.dev.a-live.tokyo/static_info/event_note.json"
  }
  return request({
      url: url,
      method: 'get',
  })
}

export function getSystemNgWord() {
  return request({
      url: `/system/ng_word`,
      method: 'get',
  })
}
