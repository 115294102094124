import { getNewsList, readOneNews, getNewsStatus, getMaintenance } from '@/api/notice';


const getDefaultState = () => {
  return {
    list: [],
    meta: {
      total: 0,
      readed_total: 0,
    },
    num: {
      total: 0,
      readed: 0,
      new: 0,
    },
    maintenance: 0,
  }
}

const state = getDefaultState()

const mutations = {
  SET_LIST: (state, data) => {
    state.list = data
  },
  SET_META: (state, meta) => {
    state.meta.total = meta.pagination.total
    state.meta.readed_total = meta.readed_total
  },
  SET_NUM: (state, data) => {
    state.num.total = data.total
    state.num.readed = data.readed_total
    state.num.new = data.total - data.readed_total
  },
  SET_MAINTE: (state, data) => {
    state.maintenance = data
  },
}

const actions = {
  async syncNewsList({ commit }) {
    const { data } = await getNewsList();
    commit('SET_LIST', data.data.map(v => {
      v.readed = data.meta.readed_id_list.includes(v.id);
      return v
    }));
    commit('SET_META', data.meta);
  },
  async syncNewsStatus({commit}) {
    const { data } = await getNewsStatus();
    commit('SET_NUM', data);
  },
  async readNews({ dispatch }, id) {
    const { data } = await readOneNews(id);
    await dispatch('syncNewsStatus')
    return data;
  },
  async syncMaintenance({ commit }) {
    const { data } = await getMaintenance();
    if (data.data.length > 0) {
      commit('SET_MAINTE', data.data[0].effective_time);
    } else {
      commit('SET_MAINTE', 0);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

