const TOKEN_KEY = 'akipro_token_key_v2'
const TOKEN_SAVE = 'akipro_token_save_v2'
const TOKEN_SAVE_EMAIL = 'akipro_token_save_email_v2'

export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY)
}

export function setToken(token) {
  removeToken();

  if (getIsSaveFlag()) {
    localStorage.setItem(TOKEN_KEY, token)
  } else {
    sessionStorage.setItem(TOKEN_KEY, token)
  }
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
  sessionStorage.removeItem(TOKEN_KEY)
}

export function setIsSaveFlag(flag, email) {
  if (flag) {
    localStorage.setItem(TOKEN_SAVE, flag)
    localStorage.setItem(TOKEN_SAVE_EMAIL, email)
  } else {
    localStorage.removeItem(TOKEN_SAVE)
    localStorage.removeItem(TOKEN_SAVE_EMAIL)
  }
}

export function getIsSaveFlag() {
  return localStorage.getItem(TOKEN_SAVE)
}

export function getEmailSaved() {
  return localStorage.getItem(TOKEN_SAVE_EMAIL)
}