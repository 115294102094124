import TIM from 'tim-js-sdk';
const OBSWebSocket = require('obs-websocket-js');

import { createLiveNormal, setLiveNormalS2, startLive, startLivePre, stopLive, showPlayUrl, getLiveAllListS3, createAndSetLiveEventS2, createAndSetLiveClubfunS2, createAndSetLivePayS2, getM3u8Url, getLiveInfo, getLiveHistoryComments, getLiveListEventRank, getLiveClubfun, getLiveViewerCount, getLiveNormalListS3, getLiveEventListS3, getLivePaidListS3, getLiveClubfunListS3, getLiveClubfunListS4, updateChangeLiveEventS2 } from '@/api/live'
import { getTargetListS2, getTargetRankList } from '@/api/target'
import { LIVE_TYPE } from '@/views/Live/helper/def'
import _ from 'lodash'
import { getDefaultGiftList } from '@/store/modules/system'

const getDefaultState = () => {
  return {
    // config
    SDKAppID: 1400466077,

    // instances
    insTIM: undefined,
    insHLS: undefined,
    insOBS: undefined,

    // live info from server
    info: {
      duration: undefined,
      ended_at: undefined,
      expire_time: undefined,
      gift_amount: undefined,
      id: undefined,
      liveable_id: undefined,
      liveable_type: undefined,
      message_group: undefined,
      point: undefined,
      profile_id: undefined,
      public_time: undefined,
      status: undefined,
      room_info: {
        banner_store_path: undefined,
        name: undefined,
        twitter_text: undefined,
      },
      profile: {
        account_id: undefined,
        account_name: undefined,
        attribute: undefined,
        avatar_store_path: undefined,
        background_store_path: undefined,
        birthday: undefined,
        created_at: undefined,
        id: undefined,
        introduction: undefined,
        profileable_id: undefined,
        profileable_type: undefined,
        sns_infos: undefined,
        updated_at: undefined,
      },
      sig: undefined,
      started_at: undefined,
      stream_name: undefined,
      streamer_id: undefined,
      streamer_info_id: undefined,
      viewer_count: undefined,
      target: {
        id: undefined,
        streamer_id: undefined,
        name: undefined,
        target_point: undefined,
        current_point: undefined,
        content: undefined,
        banner_store_path: undefined,
        stage: undefined,
        updated_at: undefined,

        percentString: 0,
        ranking: [],
      },
    },

    // live comment list
    commentList: [],
    once_send_gift_profile_ids: [],
    liveAttributeInfo:{
      fire_time: undefined,
      ice_time: undefined,
      wind_time: undefined,
      earth_time: undefined,
      phoenix_time: undefined,
      dragon_time: undefined,
      pegasus_time: undefined,
      white_tiger_time: undefined,
      booster_type: {
        description: undefined,
        key: undefined,
        value: undefined,
      },
      booster_time: undefined,
      all_gift_booster_time: undefined,
    },

    liveEventRank: 0,
    liveClubfun: {
      id: undefined,
      streamer_id: undefined,
      title: undefined,
      banner_store_path: undefined,
      describe: undefined,
      "coin_infos_1-17-1_price": undefined,
      "coin_infos_1-17-2_price": undefined,
      "coin_infos_1-17-3_price": undefined,
      "coin_infos_1-17-4_price": undefined,
      "coin_infos_1-17-5_price": undefined,
      "coin_infos_1-17-1_target_amount": undefined,
      "coin_infos_1-17-2_target_amount": undefined,
      "coin_infos_1-17-3_target_amount": undefined,
      "coin_infos_1-17-4_target_amount": undefined,
      "coin_infos_1-17-5_target_amount": undefined,
      "current_coin_amount_1-17-1": undefined,
      "current_coin_amount_1-17-2": undefined,
      "current_coin_amount_1-17-3": undefined,
      "current_coin_amount_1-17-4": undefined,
      "current_coin_amount_1-17-5": undefined,
      target_cash: undefined,
      current_cash: undefined,
      achievement_condition: undefined,
      recruitment_method: undefined,
      start_time: undefined,
      end_time: undefined,
      public_time: undefined,
      twitter_text: undefined,
      claim: undefined,
      result: undefined,
      is_posted: undefined,
    },

    liveClubfunProcess: 0,
    current_percentage:{
      ones:0,
      tens:0,
      hundreds:0,
      thousands:0
    },

    // live status
    liveURL: '',
    onlineCount: 0,
    tsPlus: undefined,
    tsLastExpire: -999999,

    // special for streamer
    // OBS config
    stream: {
      settings: {
        server: '',
        key: '',
      }
    },
    obsAddress: '127.0.0.1:4444',

    // special for streamer
    // 0: idle, 1: pre-streaming, 2: streaming, 3: pre-idle
    // liveStatus: 0,
    liveId: -1,

    state_info: {
      normar_title: '',
      clubfun_title: '',
      pay_title: '',
    }
  }
}

const getTargetPercentage = (current,total)=>{
  if(!current || !total){
    return 0
  }
  const num = Math.floor(current / total * 100);
  return `${num > 100 ? 100 : num}`
}
const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    const initState = getDefaultState();
    const keys = Object.keys(initState);
    for(let i = 0; i !== keys.length; i++) {
      if (_.isObject(initState[keys[i]])) {
        state[keys[i]] = Object.assign({}, initState[keys[i]]);
      } else {
        state[keys[i]] = initState[keys[i]];
      }
    }
  },

  // instance
  SET_INS_TIM: (state, ins) => {
    state.insTIM = ins
  },
  SET_INS_HLS: (state, ins) => {
    state.insHLS = ins
  },
  SET_INS_OBS: (state, ins) => {
    state.insOBS = ins
  },

  // init comment list
  INIT_COMMENT_LIST: (state) => {
    state.commentList = []
  },

  // set comment list
  SET_COMMENT_LIST: (state, value) => {
    state.commentList = value;
  },
  // comment
  ADD_TO_COMMENT_LIST: (state, oneComment) => {
    state.commentList.push(oneComment)
  },

  SET_GIFT_SEND_LIST: (state, profileIdList) => {
    state.once_send_gift_profile_ids = profileIdList;
  },
  // comment
  ADD_TO_GIFT_SEND_LIST: (state, profileId) => {
    state.once_send_gift_profile_ids.push(profileId);
  },

  SET_LIVE_EVENT_RANK: (state, value) => {
    state.liveEventRank = value;
  },

  // live info
  SET_LIVE_INFO: (state, value) => {
    state.info.duration = value.duration;
    state.info.ended_at = value.ended_at;
    state.info.expire_time = value.expire_time;
    state.info.gift_amount = value.gift_amount;
    state.info.id = value.id;
    state.info.liveable_id = value.liveable_id;
    state.info.liveable_type = value.liveable_type;
    state.info.message_group = value.message_group;
    state.info.point = value.point;
    state.info.profile_id = value.profile_id;
    state.info.public_time = value.public_time;
    state.info.status = value.status;

    state.info.room_info.banner_store_path = value.room_info.banner_store_path;
    state.info.room_info.name = value.room_info.name;
    state.info.room_info.twitter_text = value.room_info.twitter_text;

    state.info.profile.account_id = value.profile.account_id;
    state.info.profile.account_name = value.profile.account_name;
    state.info.profile.attribute = value.profile.attribute;
    state.info.profile.avatar_store_path = value.profile.avatar_store_path;
    state.info.profile.background_store_path = value.profile.background_store_path;
    state.info.profile.birthday = value.profile.birthday;
    state.info.profile.created_at = value.profile.created_at;
    state.info.profile.id = value.profile.id;
    state.info.profile.introduction = value.profile.introduction;
    state.info.profile.profileable_id = value.profile.profileable_id;
    state.info.profile.profileable_type = value.profile.profileable_type;
    state.info.profile.sns_infos = value.profile.sns_infos;
    state.info.profile.updated_at = value.profile.updated_at;

    state.info.sig = value.sig;
    state.info.started_at = value.started_at;
    state.info.stream_name = value.stream_name;
    state.info.streamer_id = value.streamer_id;
    state.info.streamer_info_id = value.streamer_info_id;
    state.info.viewer_count = value.viewer_count;
    state.onlineCount = value.viewer_count;
  },
  SET_LIVE_ID: (state, id) => {
    state.info.id = id;
  },

  SET_LIVE_TARGET: (state, target) => {
    state.info.target.id = target.id
    state.info.target.streamer_id = target.streamer_id
    state.info.target.name = target.name
    state.info.target.target_point = target.target_point
    state.info.target.current_point = target.current_point;
    state.info.target.content = target.content
    state.info.target.banner_store_path = target.banner_store_path
    state.info.target.stage = target.stage
    state.info.target.updated_at = target.updated_at

    state.info.target.percentString= target.percentString || 0
    state.info.target.ranking = target.ranking
  },

  SET_LIVE_TARGET_PROGRESS: ( {state}, param)=>{
    state.info.target.current_point = param.currentPoint
    state.info.target.percentString = param.percentString
  },

  // live status
  SET_LIVE_URL: (state, url) => {
    state.liveURL = url;
  },
  SET_ONLINE_COUNT: (state, num) => {
    state.onlineCount = num
  },
  SET_TS_PLUS: (state, value) => {
    state.tsPlus = value;
  },
  SET_TS_LAST_EXPIRE: (state, value) => {
    state.tsLastExpire = value;
  },

  SET_LIVE_CLUBFUN:  (state, value) => {
    state.liveClubfun.id = value.id;
    state.liveClubfun.streamer_id = value.streamer_id;
    state.liveClubfun.title = value.title;
    state.liveClubfun.banner_store_path = value.banner_store_path;
    state.liveClubfun.describe = value.describe;
    state.liveClubfun["coin_infos_1-17-1_price"] = value["coin_infos_1-17-1"].price
    state.liveClubfun["coin_infos_1-17-2_price"] = value["coin_infos_1-17-2"].price
    state.liveClubfun["coin_infos_1-17-3_price"] = value["coin_infos_1-17-3"].price
    state.liveClubfun["coin_infos_1-17-4_price"] = value["coin_infos_1-17-4"].price
    state.liveClubfun["coin_infos_1-17-5_price"] = value["coin_infos_1-17-5"].price
    state.liveClubfun["coin_infos_1-17-1_target_amount"] = value["coin_infos_1-17-1"].target_amount
    state.liveClubfun["coin_infos_1-17-2_target_amount"] = value["coin_infos_1-17-2"].target_amount
    state.liveClubfun["coin_infos_1-17-3_target_amount"] = value["coin_infos_1-17-3"].target_amount
    state.liveClubfun["coin_infos_1-17-4_target_amount"] = value["coin_infos_1-17-4"].target_amount
    state.liveClubfun["coin_infos_1-17-5_target_amount"] = value["coin_infos_1-17-5"].target_amount
    state.liveClubfun["current_coin_amount_1-17-1"] = value["current_coin_amount_1-17-1"];
    state.liveClubfun["current_coin_amount_1-17-2"] = value["current_coin_amount_1-17-2"];
    state.liveClubfun["current_coin_amount_1-17-3"] = value["current_coin_amount_1-17-3"];
    state.liveClubfun["current_coin_amount_1-17-4"] = value["current_coin_amount_1-17-4"];
    state.liveClubfun["current_coin_amount_1-17-5"] = value["current_coin_amount_1-17-5"];
    state.liveClubfun.target_cash = value.target_cash;
    state.liveClubfun.current_cash = value.current_cash;
    state.liveClubfun.achievement_condition = value.achievement_condition;
    state.liveClubfun.recruitment_method = value.recruitment_method;
    state.liveClubfun.start_time = value.start_time;
    state.liveClubfun.end_time = value.end_time;
    state.liveClubfun.public_time = value.public_time;
    state.liveClubfun.twitter_text = value.twitter_text;
    state.liveClubfun.claim = value.claim;
    state.liveClubfun.result = value.result;
    state.liveClubfun.is_posted = value.is_posted;
    state.liveClubfunProcess = value.current_percentage;
  },

  SET_CURRENT_PERCENTAGE: (state, value) => {
    state.liveClubfunProcess = value.current_percentage;
    state.current_percentage.ones = value.ones
    state.current_percentage.tens = value.tens
    state.current_percentage.hundreds = value.hundreds
    state.current_percentage.thousands = value.thousands
  },

  // special for streamer
  // SET_LIVE_STATUS: (state, status) => {
  //   state.liveStatus = status
  // },

  // special for streamer
  SET_STREAM_SETTINGS: (state, settings) => {
    state.stream.settings.server = settings.server;
    state.stream.settings.key = settings.key;
    state.liveURL = settings.liveURL;
  },

  // 必要な定数
  SET_STATE_INFO: ( state, info) => {
    state.state_info = info
  },
  SET_LIVE_POINT: (state, value) => {
    state.info.point = value;
  },
  UPDATE_LIVE_ATTR_INFO: (state, liveAttributeInfo) => {
    state.liveAttributeInfo = liveAttributeInfo;
  },
}

const actions = {
  async reset({ commit }) {
    commit('RESET_STATE');
  },

  async syncLiveEventRank({ commit, state }) {
    const { data } = await getLiveListEventRank(state.info.liveable_id, state.info.streamer_id);
    let rank = data[0]
    if(!(typeof rank === 'number' && !isNaN(rank))){
      rank = 0
    }
    commit('SET_LIVE_EVENT_RANK', rank);
  },

  // live
  async liveInit({ commit, dispatch, state }, param) {
    const idFromParam = param.id;
    let data = {};
    try {
      const retInfo = await getLiveInfo(idFromParam);
      data = retInfo.data;
    } catch(e) {
      // specail for streamer
      console.log(e);
    }

    const defaultGiftCostList = getDefaultGiftList();
    function commentListMap(data) {
      data.map(v => {
        v.payload.data = v.payload.Data
        v.payload.description = v.payload.Desc
        v.payload.Ext.GIFT_ID = defaultGiftCostList[v.payload.Ext.RAW.type] ? defaultGiftCostList[v.payload.Ext.RAW.type].name : null;
        v.payload.Ext.giftIcon = defaultGiftCostList[v.payload.Ext.RAW.type] ? require("../../assets/gift-icon/" + defaultGiftCostList[v.payload.Ext.RAW.type].icon) : null;
        v.payload.extension = JSON.stringify(v.payload.Ext);
        return v
      }).reverse();
      return data;
    }

    try{
      const reqTs = parseInt(new Date().valueOf());
      const ret = await getLiveHistoryComments(data.id, reqTs);
      ret.data.data = commentListMap(ret.data.data);

      commit('SET_COMMENT_LIST', ret.data.data);
      commit('SET_GIFT_SEND_LIST', ret.data.once_send_gift_profile_ids);
    } catch(e) {
      console.log('Get History Comment Failed!')
      console.log(e)
    }
    if (idFromParam !== '' && idFromParam !== undefined) {
      commit('SET_LIVE_INFO', data);
      if(state.info.liveable_type === "NORMAL"){
        await dispatch('syncTarget');
      }
      if(state.info.liveable_type === "EVENT"){
        await dispatch('syncLiveEventRank');
      }
      if (state.info.liveable_type === "FUND") {
        await dispatch('syncLiveClubfun');
      }
      return true;
    } else {
      return false;
    }
  },

  async syncLiveInfo({ commit }, id) {
    const retInfo = await getLiveInfo(id);
    commit('SET_LIVE_INFO', retInfo.data);
  },

  async syncLiveClubfun({ commit, dispatch, state }) {
    const { data } = await getLiveClubfun(state.info.liveable_id);
    commit('SET_LIVE_CLUBFUN', data);
    await dispatch('setCurrentPercentageInfo',state.liveClubfunProcess)
  },

  async syncTarget({ commit }) {
    try {
      const ret = await getTargetListS2()
      if(ret.data.data.length > 0) {
        const ret2 = await getTargetRankList(ret.data.data[0].id)
        ret.data.data[0].ranking = ret2.data
        ret.data.data[0].percentString = getTargetPercentage(ret.data.data[0].current_point,ret.data.data[0].target_point)
        commit('SET_LIVE_TARGET', ret.data.data[0]);
      }
    } catch(e) {
      console.log(e);
      commit('SET_LIVE_TARGET', {});
    }
  },

  updateTargetPoint({commit,state},currentPoint){
    if (!state.info.liveable_type || state.info.liveable_type !== "NORMAL") {
      return;
    }
    try {
      const percentString = getTargetPercentage(currentPoint,state.info.target.target_point)
      commit('SET_LIVE_TARGET_PROGRESS',{currentPoint:currentPoint, percentString:percentString});
    } catch(e) {
      commit('SET_LIVE_TARGET_PROGRESS', 0);
    }
  },

  async syncLiveURL({commit, state, rootState}) {
    if (state.info.id === -1) {
      return;
    }
    if (state.tsPlus === undefined) {
      const result = new Date().getTime()/1000 - rootState.system.timestamp;
      commit('SET_TS_PLUS', result);
    }
    if (state.tsLastExpire - new Date().getTime()/1000 + state.tsPlus < 20) {
      const data = (await getM3u8Url(state.info.id)).data;
      commit('SET_TS_LAST_EXPIRE', data.expire_time);
      return commit('SET_LIVE_URL', data.url);
    }
  },

  // tim
  async timInit({ commit, state }) {
    commit('SET_INS_TIM', TIM.create({
      SDKAppID: state.SDKAppID,
    }));

    state.insTIM.setLogLevel(6);
  },
  async timLogin({ state, rootState }) {
    await state.insTIM.login({
      userID: rootState.user.module + rootState.user.id,
      userSig: state.info.sig
    });
  },
  async timLogout({ state }) {
    await state.insTIM.logout();
  },
  async timJoinGroup({ state }) {
    const { data } = await state.insTIM.joinGroup({
      groupID: state.info.message_group,
      type: TIM.TYPES.GRP_AVCHATROOM
    });
    switch (data.status) {
      case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL:
        break;
      case TIM.TYPES.JOIN_STATUS_SUCCESS:
        break;
      case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP:
        break;
      default:
        break;
    }
  },
  async timUpdateOnlineCount({ commit, state }) {
    // const { data } = await state.insTIM.getGroupOnlineMemberCount(state.info.message_group);
    // commit('SET_ONLINE_COUNT', data.memberCount || 0);
    const ret = await getLiveViewerCount(state.info.id);
    commit('SET_ONLINE_COUNT', ret.data.viewer_count || 0);
  },
  async timUpdateCommentList({ commit,state,dispatch }, commentOne) {
    // イベント処理
    if ( commentOne.payload.data == 'event' ){
      switch( commentOne.payload.description ){
        // ライブ情報更新
        case 'LIVE_INFO' :
          //commit('UPDATE_LIVE_ATTR_INFO', JSON.parse(commentOne.payload.extension).RAW);
          dispatch('updateLiveAttrInfo', JSON.parse(commentOne.payload.extension).RAW);
          break;
        }
      } else {
        // ギフトであればギフト送ったID一覧を更新
        if( commentOne.payload.data === 'gift'){
          const profile_id = JSON.parse(commentOne.payload.extension).PROFILE_ID
          if(profile_id && state.once_send_gift_profile_ids.indexOf(profile_id) == -1) {
            commit('ADD_TO_GIFT_SEND_LIST', profile_id);
          }
          // クラファン配信であればクラファンの情報を更新
          if (state.info.liveable_type === "FUND") {
            await dispatch('syncLiveClubfun');
          }
          // ポイントとクラファン達成率の更新
          let item = JSON.parse(commentOne.payload.extension)
          if( item.RAW ){
            const current_percentage = item.RAW.current_percentage;
            if (state.info.liveable_type === "FUND" && current_percentage) {
              dispatch('setCurrentPercentageInfo',current_percentage)
            }
            const currentPoint = item.RAW.live_point
            commit('SET_LIVE_POINT',currentPoint)
          }
        }
        // Live情報更新
        // await dispatch('syncLiveInfo')


      // コメント内容を更新
      commit('ADD_TO_COMMENT_LIST', commentOne);
    }
  },

  setCurrentPercentageInfo({ commit }, current_percentage) {
    const temp = current_percentage <= 9999 ? current_percentage : 9999
    const ones = temp % 10
    const tens = Math.floor(temp % 100 / 10 )
    const hundreds = Math.floor(temp % 1000 / 100)
    const thousands = Math.floor(temp % 10000 / 1000)
    const value = {
      current_percentage:current_percentage,
      ones:ones,
      tens:tens,
      hundreds:hundreds,
      thousands:thousands
    }
    commit('SET_CURRENT_PERCENTAGE',value)
  },

  updateLiveAttrInfo({ commit }, liveAttrInfo) {
    commit('UPDATE_LIVE_ATTR_INFO', liveAttrInfo);
  },

  async timSendMessagePlain({rootState, state }, data) {
    const { text } = data;
    let message = state.insTIM.createCustomMessage({
      to: state.info.message_group,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        data: 'text',
        description: text,
        extension: JSON.stringify({
          NICKNAME: state.info.profile.account_name,
          AVATAR: rootState.user.avatar_store_path,
          LIVE_ID: state.info.id,
          PROFILEABLE_TYPE: 'streamer',
          ATTRIBUTE:state.info.profile.attribute,
          PROFILE_ID:state.info.profile.id
        }),
      }
    })
    await state.insTIM.sendMessage(message);
  },

  // special for streamer
  // OBS
  obsInit({ commit }) {
    commit('SET_INS_OBS', new OBSWebSocket());
  },
  async obsConnect({ state }) {
    await state.insOBS.connect({ address: state.obsAddress });
  },
  async obsDisconnect({ state }) {
    await state.insOBS.disconnect();
  },
  async obsSetPushConfig({ state }) {

    state.insOBS.send('SetStreamSettings', {
      type: 'rtmp_custom',
      settings: {
        server: state.stream.settings.server,
        key: state.stream.settings.key,
      },
    });
  },
  async obsStartStreaming({state}){
    if(state.insOBS && state.insOBS._connected){
      await state.insOBS.send('StartStreaming');
    }
  },
  async obsStopStreaming({state}){
    if(state.insOBS && state.insOBS._connected){
      return state.insOBS.send('StopStreaming')
    }
  },
  async obsIsStreaming(){
    const { streaming } = await state.insOBS.send('GetStreamingStatus');
    return streaming;
  },

  async getStreamingLive({ state, commit, dispatch }, info) {
    const liveType = info.liveType;
    let StreamingLive = undefined
    if (liveType === LIVE_TYPE.NORMAL) {
      StreamingLive = await getLiveNormalListS3()
    } else if (liveType === LIVE_TYPE.EVENT) {
      StreamingLive = await getLiveEventListS3()
    } else if (liveType === LIVE_TYPE.FUN_CLUB) {
      StreamingLive = await getLiveClubfunListS3()
      if(!StreamingLive.data.data || StreamingLive.data.data.length === 0) {
        StreamingLive = await getLiveClubfunListS4(info.liveableId);
      }
    } else if (liveType === LIVE_TYPE.PAID) {
      StreamingLive = await getLivePaidListS3()
    }
    if(StreamingLive.data.data && StreamingLive.data.data.length > 0){
      const LiveInfo = StreamingLive.data.data[0]
      const liveId = StreamingLive.data.data[0].id
      await dispatch('liveInit', {id:liveId});
      if (state.info.liveable_type === "NORMAL") {
        await dispatch('syncTarget');
      }
      if (state.info.liveable_type === "EVENT") {
        await dispatch('syncLiveEventRank');
      }
      if (state.info.liveable_type === "FUND") {
        await dispatch('syncLiveClubfun');
      }
      // commit('INIT_COMMENT_LIST')
      let arr = LiveInfo.push_url.split('/');
      const key = LiveInfo.stream_key;
      commit('SET_STREAM_SETTINGS', {
        server: `${arr.join('/')}/`,
        key,
        liveURL: (await showPlayUrl(state.info.id)).data.url,
      });
      return true
    }
    return false
  },

  // special for streamer
  async createLive({ state, commit, dispatch }, { liveType, id, duration, reservedOverrideId }) {
    if (liveType === LIVE_TYPE.NORMAL) {
      if (!id) {
        const temp_id = (await createLiveNormal()).data.id;
        id = (await setLiveNormalS2(temp_id)).data.id;
      }
    } else if (liveType === LIVE_TYPE.EVENT) {
      if( !reservedOverrideId ){
        id = (await createAndSetLiveEventS2(id)).data.id;
      } else{
        // 予約配信をイベント配信として書き換える
        const res = await updateChangeLiveEventS2(id,reservedOverrideId)
        id = res.data.id;
      }
    } else if (liveType === LIVE_TYPE.FUN_CLUB) {
      id = (await createAndSetLiveClubfunS2(id)).data.id;
    } else if (liveType === LIVE_TYPE.PAID) {
      id = (await createAndSetLivePayS2(id)).data.id;
    }
    commit('SET_LIVE_ID', id);

    try {
      await startLivePre(state.info.id, duration);
      const ret2 = await startLive(state.info.id);
      await dispatch('liveInit', {id});
      commit('INIT_COMMENT_LIST')
      let arr = ret2.data.push_url.split('/');
      const key = ret2.data.stream_key;
      commit('SET_STREAM_SETTINGS', {
        server: `${arr.join('/')}/`,
        key,
        liveURL: (await showPlayUrl(state.info.id)).data.url,
      });
    } catch (err) {
      if (err.response.status === 400) {
        alert("この配信は一度終了しております");
        return;
      }
    }
  },

  // special for streamer
  async syncLiveId({ commit, dispatch }){
    const ret = await getLiveAllListS3();
    if (ret.data.data[0]) {
      const id = ret.data.data[0].id;
      commit('SET_LIVE_ID', id);

      await dispatch('liveInit', {
        id,
        liveable_id: ret.data.data[0].liveable_id,
      });

      return true;
    } else {
      return false;
    }
  },

  async stopLive({ state }) {
    if(state.info.id){
      await stopLive(state.info.id);
    }
    // commit('SET_LIVE_ID', -1);
  },

  async setStateInfo({ commit }, info) {
    commit('SET_STATE_INFO', info);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}