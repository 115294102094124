import request from '@/utils/request'

export function getNgWordList() {
  return request({
      url: `/forbidden_word_list`,
      method: 'get',
  })
}

export function getNgWordIgnoreCommonList() {
  return request({
      url: `/forbidden_word_list?ignore_common=1`,
      method: 'get',
  })
}

export function updateNgWordList(wordList) {
  return request({
      url: `/forbidden_word_list`,
      method: 'put',
      data: {
        "word_list": wordList
      },
  })
} 