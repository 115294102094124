import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/auth',
        method: 'post',
        data
    })
}

export function refresh() {
    return request({
        url: '/auth',
        method: 'put'
    })
}

export function getInfoFromAuth() {
    return request({
        url: '/auth',
        method: 'get'
    })
}

export function logout() {
    return request({
        url: '/auth',
        method: 'delete'
    })
}

// {
//     "profile":{
//         "account_name":"streamer-name2",
//         "attribute":"a1",
//         "introduction":"fine ok",
//         "avatar_store_path":"streamer/1/H648Pvm6JWHlmhaFhVvsVKB7Smg2Re30bmNLObsj.jpg",
//         "sns_infos": {
//             "a":"dffdfd",
//             "ab":"dffdfd"
//         }
//     }
// }
export function setUserInfoFromProfile(data) {
    return request({
        url: '/user_info',
        method: 'put',
        data
    })
}

// "data": {
//     "id": 1,
//     "streamer_id": 1,
//     "profile": {
//         "id": 1,
//         "account_id": "StreamerAccountId1",
//         "account_name": "StreamerAccounName1",
//         "introduction": "AreYouOk",
//         "birthday": "2021-04-16 18:16:10",
//         "avatar_store_path": "",
//         "background_store_path": "",
//         "attribute": "a0",
//         "sns_infos": null,
//         "profileable_type": "STREAMER",
//         "profileable_id": 1,
//         "profileable": {
//             "id": 1,
//             "streamer_id": 1,
//             "point": 21263430,
//             "created_at": "2021-04-16 18:16:10",
//             "updated_at": "2021-04-22 14:55:37"
//         }
//     }
// },
export function getUserInfoFromProfile() {
    return request({
        url: '/user_info',
        method: 'get'
    })
}


export function uploadAvatar(form) {
    return request.post('avatar', form, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}