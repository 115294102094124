<template lang="pug">
  .root
    component-system-message
    h4 [ 基本操作 ]
    div(class="button-panel")
      div(class="pc")
        a(@click="onLive()") 配信をする
      div(class="sp")
        a() 配信をする
      div(class="notice-button")
        a(@click="$router.push({ name: 'NewsIndex' })") お知らせ
          span(v-if="getUnReadNewsCount() > 0") {{ getUnReadNewsCount() }}
      div
        a(@click="$router.push({ name: 'ProfileIndex' })") プロフィール設定
      div
        a(@click="$router.push({ name: 'QuestionIndex' })") アンケート設定
      div
        a(@click="$router.push({ name: 'HelpIndex' })") ヘルプ(配信者向け)
    h4 [ 配信設定 ]
    div(class="button-panel")
      div
        a(@click="$router.push({ name: 'SettingRoomNameNormal' })") ルーム名を設定
      div
        a(@click="$router.push({ name: 'SettingRoomBannerNormal' })") ルームバナー設定
      div
        a(@click="$router.push({ name: 'SettingTargetNew' })") ターゲット設定
      div
        a(@click="$router.push({ name: 'SettingEventRecently' })") 近日開催イベント
      div
        a(@click="$router.push({ name: 'SettingScheduleConfirmIndex' })") 配信スケジュール
      div
        a(@click="$router.push({ name: 'SettingEventManage' })") 参加イベント管理
      div
        a(@click="$router.push({ name: 'SettingTwitterShareNormal' })") 拡散用テキスト設定
      div
        a(@click="$router.push({ name: 'SettingNGWord' })") ＮＧワード設定
      div
        a(@click="$router.push({ name: 'SettingBlockList' })") ブロックリスト
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "ComponentTopIndex",
  components: {},
  data() {
    return {
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.$store.dispatch('news/syncNewsList');
    await this.$store.dispatch('user/syncProfileStatus');
  },
  updated() {},
  destroyed() {},
  methods: {
    onLive() {
      // 非公開の状態で配信はできません
      if (this.$store.state.user.profile.status !== "public") {
        this.$alert("このアカウントは現在非公開状態になっています。運営者の方へご確認ください。")
        return
      }
      this.$router.push({ name: 'SelectIndex' })
    },
    getUnReadNewsCount() {
      return this.$store.state.news.meta.total - this.$store.state.news.meta.readed_total
    },
  }
};
</script>
