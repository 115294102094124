<template lang="pug">
  .root
    <div v-if="validationErrors" class="m-2" style="margin-top: 10px;">
      <ul class="alert alert-danger list-unstyled">
        <li v-for="(value, index) in validationErrors" :key="index">{{value}}</li>
      </ul>
    </div>
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
export default {
  name: "ComponentsError",
  components: {},
  props: {
    errors: Array
  },
  data() {
    return {}
  },
  async created() { },
  mounted() {},
  computed: {
    validationErrors() {
      return this.errors.flat()
    }
  },
  methods: {},
  watch: {}
}
</script>