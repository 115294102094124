<template lang="pug">
  .root
    div(class="container-flex")
      div(class="footer-content")
        div(class="footer-logo-content")
          a(@click="$router.push({ name: 'TopIndex' })" class="footer-logo") 
            img(src="/img/logo.png")
        div(class="footer-link")
          a(@click="$router.push({ name: 'TermsIndex' })" class="link-color") 利用規約
</template>

<style src="./footer.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "ComponentsFooterLogin",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>