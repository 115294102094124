import request from '@/utils/request'

export function createLiveNormal() {
    return request({
        url: '/broadcast_normals',
        method: 'post'
    })
}

export function createAndSetLiveEventS2(event_id) {
    return request({
        url: `/broadcast_events/${event_id}/lives`,
        method: 'post',
        data: {
            "status": "s2"
        }
    })
}

export function updateChangeLiveEventS2(event_id,live_id) {
    return request({
        url: `/broadcast_events/${event_id}/lives/${live_id}/updateType`,
        method: 'post',
        data: {
            "status": "s2"
        }
    })
}

export function createAndSetLiveClubfunS2(fund_id) {
    return request({
        url: `/broadcast_funds/${fund_id}/lives`,
        method: 'post',
        data: {
            "status": "s2"
        }
    })
}

export function createAndSetLivePayS2(pay_id) {
    return request({
        url: `/broadcast_pays/${pay_id}/lives`,
        method: 'post',
        data: {
            "status": "s2"
        }
    })
}

export function setLiveNormalS1(live_id, schedule_start_time) {
    return request({
        url: `/broadcast_normals/${live_id}/lives`,
        method: 'post',
        data: {
            "status": "s1",
            schedule_start_time,

        }
    })
}
export function setLiveNormalS2(live_id) {
    return request({
        url: `/broadcast_normals/${live_id}/lives`,
        method: 'post',
        data: {
            "status": "s2"
        }
    })
}




export function startLive(live_id) {
    return request({
        url: `/lives/${live_id}`,
        method: 'put',
        data: {
            "status": "s3"
        }
    })
}

export function startLivePre(live_id, duration) {
    return request({
        url: `/lives/${live_id}`,
        method: 'put',
        data: {
            "status": "s2",
            "duration": `${duration}`
        }
    })
}

export function stopLive(live_id) {
    return request({
        url: `/lives/${live_id}`,
        method: 'put',
        data: {
            "status": "s4"
        }
    })
}



export function showPlayUrl(live_id) {
    return request({
        url: `/lives/${live_id}/pull_url`,
        method: 'get',
    })
}



export function getLiveDetail(live_id) {
    return request({
        url: `/lives/${live_id}`,
        method: 'get',
    })
}



export function getLiveAllListS1() {
    return request({
        url: `/lives?search=status:s1&orderBy=schedule_start_time&sortedBy=asc`,
        method: 'get',
    })
}

export function getLiveAllListS3() {
    return request({
        url: `/lives?search=status:s3`,
        method: 'get',
    })
}

export function getLiveNormalListS3() {
    return request({
        url: `broadcast_normals/lives?search=status:s3`,
        method: 'get',
    })
}

export function getLiveEventListS3() {
    return request({
        url: `broadcast_events/lives?search=status:s3`,
        method: 'get',
    })
}

export function getLivePaidListS3() {
    return request({
        url: `broadcast_pays/lives?search=status:s3`,
        method: 'get',
    })
}

export function getLiveClubfunListS3() {
    return request({
        url: `broadcast_funds/lives?search=status:s3`,
        method: 'get',
    })
}

export function getLiveClubfunListS4(liveable_id) {
    return request({
        url: `broadcast_funds/lives?search=status:s4;liveable_id:${liveable_id}&searchJoin=and`,
        method: 'get',
    })
}

export function getLiveListEventShowing() {
    return request({
        url: `/broadcast_events?status=16&include=streamers,lives&orderBy=type&sortedBy=desc`,
        method: 'get',
    })
}

export function getLiveListEventToShow(limit, page) {
    return request({
        url: `/broadcast_events?status=14&include=streamers,lives&orderBy=type&sortedBy=desc&limit=${limit}&page=${page}`,
        method: 'get',
    })
}

export function getLiveListEventEntry() {
  return request({
      url: `/broadcast_events/entry_list`,
      method: 'get',
  })
}

export function getLiveListEventEntryALL() {
    return request({
        url: `/broadcast_events/entry_list_all`,
        method: 'get',
    })
  }

export function getLiveListEventEntryExpiry() {
  return request({
      url: `/broadcast_events/entry_list_expiry`,
      method: 'get',
  })
}

export function getPayToShow() {
    return request({
        url: `/broadcast_pays?status=0`,
        method: 'get',
    })
}

export function deleteLiveNormalS1(liveable_id) {
    return request({
        url: `broadcast_normals/${liveable_id}/lives`,
        method: 'delete',
    })
}

export function getLiveClubfunDetail(liveable_id) {
    return request({
        url: `/broadcast_funds/${liveable_id}?include=live`,
        method: 'get',
    })
}

export function canLiveClubfun() {
  return request({
      url: `/broadcast_funds/canLive?status=0`,
      method: 'get',
  })
}

export function getLivePayDetail(paid_id) {
    return request({
        url: `/broadcast_pays/${paid_id}`,
        method: 'get',
    })
}

export function getLiveEvent(event_id) {
    return request({
        url: `broadcast_events/${event_id}?include=lives`,
        method: 'get',
    })
}

export function getLiveEventDetail(event_id) {
    return request({
        url: `broadcast_events/${event_id}?include=lives,streamers`,
        method: 'get',
    })
}

/**
 * イベントに参加する
 */
export function putEntryEvent(event_id) {
    return request({
        url: `/broadcast_events/${event_id}/entry`,
        method: 'put',
        data: {}
    })
}

/**
 * イベントに参加している配信者一覧
 */
export function getLiveListEventEntryStreamer(event_id) {
  return request({
      url: `/broadcast_events/${event_id}/entry_streamer`,
      method: 'get',
  })
}

/**
* イベントに参加している配信者のランキング
*/
export function getLiveListEventRanking(event_id) {
  return request({
      url: `/broadcast_events/${event_id}/ranks`,
      method: 'get',
  })
}

/**
 * イベントランキングで自身が何位か取得する
 */
export function getLiveListEventRank(event_id, streamer_id) {
    return request({
        url: `/broadcast_events/${event_id}/lives/${streamer_id}/rank`,
        method: 'get',
    })
}

/**
 * 応援ランキング
 */
export function getEventLiveViewerRankList(liveable_id, streamer_id) {
    return request({
        url: `/broadcast_events/${liveable_id}/streamers/${streamer_id}/ranks`,
        method: 'get',
    })
}

export function getLiveClubfun(liveable_id) {
    return request({
        url: `/broadcast_funds/${liveable_id}?include=live`,
        method: 'get',
    })
}

export function getLiveHistoryComments(id, ts) {
    return request({
        url: `lives/${id}/comments?created_at=${ts}`,
        method: 'get',
    })
}

export function getLiveInfo(id) {
    return request({
        url: `/lives/${id}`,
        method: 'get',
    })
}

export function getLivePayBuyInfo(liveable_id) {
    return request({
        url: `broadcast_pays/${liveable_id}?include=profile`,
        method: 'get'
    })
}

export function getLiveTarget(streamerId) {
    return request({
        url: `/streamers/${streamerId}/target`,
        method: 'get',
    })
}

export function getLiveTargetRanking(targetId) {
    return request({
        url: `streamers/targets/${targetId}/ranks`,
        method: 'get',
    })
}

export function getM3u8Url(id) {
    return request({
        url: `/lives/${id}/pull_url`,
        method: 'get',
    })
}

export function getLiveViewerCount(live_id) {
    return request({
        url: `lives/${live_id}/viewer_count`,
        method: 'get',
    })
}

export function getLiveAttrInfo(live_id) {
    return request({
        url: `live_infos/${live_id}`,
        method: 'get',
    })
}
