import { login, getInfoFromAuth, refresh, logout, setUserInfoFromProfile, getUserInfoFromProfile } from '@/api/user'
import { getToken, setToken, removeToken, setIsSaveFlag} from '@/utils/auth'

const getDefaultState = () => {
  return {
    token: getToken(),
    id: '', // Only For Refresh Token
    module: '', // Only For Refresh Token
    email: '',
    profile_id: '',
    attribute: '',
    avatar_store_path: '',
    profile: {
      status: ''
    },

    lastTsSyncUserInfo: 0,
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_MODULE: (state, module) => {
    state.module = module
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },

  SET_PROFILE_ID: (state, profileId) => {
    state.profileId = profileId
  },
  SET_ATTRIBUTE: (state, attribute) => {
    state.attribute = attribute
  },
  SET_PROFILE_STATUS: (state, status) => {
    state.profile.status = status
  },
  SET_LAST_TS_SYNC_USERINFO: (state, value) => {
    state.lastTsSyncUserInfo = value
  },
  SET_AVATAR_STORE_PATH: (state, avatar_store_path) => {
    state.avatar_store_path = avatar_store_path
  }
}

const actions = {
  async login({ commit, dispatch }, form) {
    const { email, password, isSave } = form
    const { data } = await login({ email: email.trim(), password: password });
    commit('SET_TOKEN', data.access_token);
    setIsSaveFlag(isSave, email);
    setToken(data.access_token);
    await dispatch('syncUserInfo');
  },

  async refresh({ commit, dispatch }) {
    try {
      const { data } = await refresh();
      commit('SET_TOKEN', data.access_token)
      setToken(data.access_token)
      await dispatch('syncUserInfo');
    } catch (e) {
      removeToken()
      commit('RESET_STATE')
    }
  },

  async syncUserInfo({ dispatch, state, commit }) {
    if (new Date().valueOf() - state.lastTsSyncUserInfo > 1 * 1000) {
      await dispatch('syncAuthInfo');
      await dispatch('syncAttribute');
      commit('SET_LAST_TS_SYNC_USERINFO', new Date().valueOf());
    }
  },

  async syncAuthInfo({ commit, state }) {
    const { data } = await getInfoFromAuth(state.token)
    const { email, profile_id } = data
    commit('SET_EMAIL', email)
    commit('SET_PROFILE_ID', profile_id)

  },

  async logout({ commit }) {
    await logout();
    removeToken();
    commit('RESET_STATE');
  },

  async syncAttribute({ commit, rootState }) {
    const { data } = await getUserInfoFromProfile();
    commit('SET_ATTRIBUTE', data.attribute || rootState.system.enum_list.attribute_list.NONE);
    commit('SET_AVATAR_STORE_PATH', data.profile.avatar_store_path);
  },

  async syncProfileStatus ({ commit }) {
    const { data } = await getUserInfoFromProfile();
    commit('SET_PROFILE_STATUS', data.profile.status);
  },

  async setProfile(context, data) {
    await setUserInfoFromProfile(data);
  },

  async getProfile() {
    const { data } = await getUserInfoFromProfile();
    return data;
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

