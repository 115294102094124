<template lang="pug">
#app
  router-view/
</template>

<style lang="scss" scoped>
</style>

<style src="./styles/global.scss" lang="scss"></style>

<script>
export default {
  name: "APP",

  components: {},

  created() {},

  data() {
    return {
      leftDrawerOpen: false
    };
  }
};
</script>

<style></style>
