<template lang="pug">
  .root
    div(class="container-flex")
      div(class="footer-content")
        div(class="footer-logo-content")
          a(@click="$router.push({ name: 'TopIndex' })" class="footer-logo")
            img(src="/img/logo.png")
        div(class="footer-link")
          a(@click="$router.push({ name: 'TermsIndex' })" class="link-color") 利用規約
          a(@click="onLogout()" v-if="isLogin()" class="link-color") ログアウト
</template>

<style src="./footer.scss" lang="scss" scoped>
</style>

<script>
import { getToken } from '@/utils/auth'

export default {
  name: "ComponentsFooterIndex",
  components: {
  },
  data() {
    return {
      isStarted(){
        return this.$store.getters.enum_list.live_status_list.ONGOING == this.$store.state.live.info.status || this.$store.getters.enum_list.live_status_list.PREPARATION == this.$store.state.live.info.status;
      },
      isLiveOver() {
        return this.$store.getters.enum_list.live_status_list.ENDED === this.$store.state.live.info.status
      },
    }
  },
  methods: {
    async onLogout() {
      if(this.isStarted && !this.isLiveOver){
        window.alert('配信中です')
        return
      }
      await this.$store.dispatch('user/logout');
      this.$router.push({ name: 'LoginIndex' });
    },
    isLogin() {
      return getToken()
    }
  }
}
</script>