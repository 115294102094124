import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import Vue from 'vue'

const service = axios.create({
    baseURL: `${process.env.VUE_APP_SERVER_PUBLIC_URL}`,
    timeout: 5000
})

service.interceptors.request.use(
    config => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        config.headers['Content-type'] = 'application/json'

        if (store.getters.token) {
          config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

let flag401 = false;

service.interceptors.response.use(
    response => {
      const res = response.data
      if (!`${response.status}`.startsWith('20')) {
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return res
      }
    },
    error => {
      var originalRequest = error.config;

      switch (error.response?.status) {
        case 401:
          if (getToken() && !flag401 && error.response.data) {
            flag401 = true;
            return store.dispatch('user/refresh').then(() => {
              originalRequest.headers.Authorization = 'Bearer ' + getToken();
              return axios.request(originalRequest).finally((ret)=>{
                flag401 = false;
                return ret;
              });
            }).catch(() => {
              flag401 = false;
              router.push({ name: 'LoginIndex' });
              return Promise.resolve()
            })
          } else {
            return runErrorProcess();
          }
        default:
          if(process.env.VUE_APP_ENV === 'DEV') {
            Vue.toasted.error(error.response.data.message);
          }
          return runErrorProcess();
      }

      function runErrorProcess() {
        const { response } = error
        let message, error_message;
        if (response && response.data) {
          message = error.response.data.message
          error_message = JSON.stringify(error.response.data.error,null,'\t');
        } else {
          message = error.message
        }
        if (Array.isArray(message)) {
          message = message.join(',')
        }
        if(process.env.VUE_APP_ENV === 'DEV') {
          console.debug(`
            message: ${message}
            error message: ${error_message}
          `);
        }
        return Promise.reject(error)
      }
    }
  )

export default service