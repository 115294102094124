import { getNgWordList, updateNgWordList } from '@/api/setting'
import { uniq, difference } from 'lodash'

const getDefaultState = () => {
  return {
    ngwordList: []
  }
}

const state = getDefaultState();

const mutations = {
  SET_NGWORD_LIST: (state, value) => {
    state.ngwordList = value
  },
}

const actions = {
  async syncNgWordList({ rootState, commit }) {
    const {data} = await getNgWordList(rootState.user.id);
    commit('SET_NGWORD_LIST', data.word_list);
  },
  async addNgWord({ state, commit }, word) {
    let newList = uniq([...state.ngwordList, word]);
    const { data } = await updateNgWordList(newList);
    commit('SET_NGWORD_LIST', data.word_list);
  },
  async removeNgWord({ state, commit }, word) {
    let newList = difference(state.ngwordList, [word]);
    const { data } = await updateNgWordList(newList);
    commit('SET_NGWORD_LIST', data.word_list);
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

