<template lang="pug">
  .root
    div(class="header-content")
      div(class="header-border")
      a(@click="$router.push({ name: 'TopIndex' })" class="header-logo") 
        img(src="/img/logo.png")
      div(class="header-border")
</template>

<style src="./header.scss" lang="scss" scoped>
</style>

<script>

export default {
  name: "ComponentsHeaderLogin",
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    
  }
}
</script>