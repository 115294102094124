import request from '@/utils/request'

export function getTargetListS1() {
    return request({
        url: `/targets?search=stage:s1&orderBy=id&sortedBy=desc`,
        method: 'get',
    })
}

export function getTargetListS2() {
    return request({
        url: `/targets?search=stage:s2&orderBy=id&sortedBy=desc`,
        method: 'get',
    })
}

export function getTargetListS3() {
    return request({
        url: `/targets?search=stage:s3&orderBy=id&sortedBy=desc`,
        method: 'get',
    })
}

export function getTargetListS4() {
    return request({
        url: `/targets?search=stage:s4&orderBy=id&sortedBy=desc`,
        method: 'get',
    })
}

export function getTarget(target_id) {
    return request({
        url: `/targets/${target_id}`,
        method: 'get',
    })
}

export function getTargetRankList(target_id) {
    return request({
        url: `/targets/${target_id}/ranks`,
        method: 'get',
    })
}

export function sendThanksEmail({target_id, viewer_id_list, message, attachment_store_path, attachment_name}) {
    return request({
        url: `/targets/${target_id}/thanks`,
        method: 'post',
        data: {
          viewer_id_list,
          message,
          attachment_store_path,
          attachment_name,
        }
    })
}

export function createTargetS1({name, target_point, content, banner_store_path}) {
    return request({
        url: `/targets`,
        method: 'post',
        data: {
          name,
          target_point,
          content,
          banner_store_path,
          stage: 's1'
        }
    })
}

export function createTargetS2({name, target_point, content, banner_store_path}) {
    return request({
        url: `/targets`,
        method: 'post',
        data: {
          name,
          target_point,
          content,
          banner_store_path,
          is_posted: true,
          stage: 's2',
        }
    })
}

export function updateTargetS2({target_id, name, target_point, content, banner_store_path}) {
    return request({
        url: `/targets/${target_id}`,
        method: 'put',
        data: {
          name,
          target_point,
          content,
          banner_store_path,
          stage: 's2'
        }
    })
}

export function updateTargetS1({target_id, name, target_point, content, banner_store_path}) {
    return request({
        url: `/targets/${target_id}`,
        method: 'put',
        data: {
          name,
          target_point,
          content,
          banner_store_path,
          stage: 's1'
        }
    })
}

export function uploadBanner(form) {
  return request.post('banners', form, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  })
}

export function uploadFile(form) {
  return request.post('attachments', form, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  })
}

