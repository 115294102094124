<template lang="pug">
  .root
    <div class="profile-right" v-if="!showBlockConfirm">
      button(@click="$emit('profileclose')" class="close-button") <i class="fas fa-times"></i>
      div(class="prof-bg-img")
      <img v-if="data.background_store_path" :src="data.background_store_path|getResUrl">
      <img v-else style="color:#FFFFFF">
      <div class="social-app-right">
        <li v-if="data.sns_infos.twitter"><a :href="data.sns_infos.twitter" target="_blank"><img src="/img/socil_twitter.png"></a></li>
        <li v-if="data.sns_infos.instagram"><a :href="data.sns_infos.instagram" target="_blank"><img src="/img/socil_instagram.png"></a></li>
      </div>
      <div class="profile-center-right">
        div(class="user-icon")
          pan-thumb(:avatar="data.avatar_store_path" :attribute="data.attribute" :current_title="current_title" :isStreamer="isStreamer(data)")
        <div class="profile-background" v-bind:style="{ background: 'url(' + bk_url +') center no-repeat' }">
          div(class="user-name" v-bind:style="{ color: this.checkBackgroundColor() }") {{ data.account_name }}
          div(class="follow-content")
            div(class="follow-content-f")
              div
                <span class="glyphicon glyphicon-user"style="color:#e85298;"></span>
                span(v-bind:style="{ color: this.checkBackgroundColor() }") {{ data.following_total }}
              div(v-bind:style="{ color: this.checkBackgroundColor() }") フォロー
            div(class="follow-content-f")
              div
                <span class="glyphicon glyphicon-user" style="color:#00afec;"></span>
                span(v-bind:style="{ color: this.checkBackgroundColor() }") {{ data.follower_total }}
              div(v-bind:style="{ color: this.checkBackgroundColor() }") フォロー
          <div class="introduction-right">
            <p><b style="color:black;">{{ data.introduction }}</b></p>
          </div>
          div(class="follow-button")
            button(:class="data.is_blocked ? 'gray-button' : 'pink-button'" @click="data.is_blocked ? onBlockCancel(data.profileable.viewer_id): onBlockButtonClick()") {{ data.is_blocked ? 'ブロック済み' : 'ブロックする' }}
            //- button(class="pink-button" @click="data.is_blocked ? onBlockCancel(data.profileable.id): onBlock(data.profileable.id)") {{ data.is_blocked ? 'ブロック済み' : 'ブロックする' }}
        </div>
      </div>
    </div>
    div(class="right-content-border-block-confirm" v-if="showBlockConfirm")
      button(@click="onCloseConfirmBlock" class="close-button") <i class="fas fa-times"></i>
      div 本当にブロックしますか？
      div(class="img-button-gemini-container")
        div(@click="onBlock(data.profileable.viewer_id)") <img src="/img/yes_p.png">
        div(@click="onCloseConfirmBlock") <img src="/img/no_p.png">
</template>

<style src="./index.scss" lang="scss" scoped>
</style>


<script>
import { getViewerProfile } from '@/api/profile';
import { addBlock, removeBlock } from '@/api/block';
import PanThumb from '@/components/PanThumb'
import { getImageUrl } from '@/utils/tools'
import { getBackgroundColorImage } from '@/utils/image'

export default {
  name: "ComponentsProfileIndex",
  components: {
    PanThumb
  },
  filters:{
    getResUrl(url){
      return getImageUrl(url)
    }
  },
  data() {
    return {
      showBlockConfirm:false,
      data: {
        sns_infos: {
          twiiter: '',
          instagram: '',
        }
      },
      bk_url: '',
      current_title: ''
    }
  },
  props: {
    profile_id: {
      type: Number,
      default: -1
    }
  },
  async created() {
    await this.init();
    this.setBackgroudColor();
  },
  methods: {
    async init() {
      const { data } = await getViewerProfile(this.profile_id);
      if (!data.sns_infos) {
        data.sns_infos = {
          twiiter: '',
          instagram: '',
        }
      }
      this.data = data;
      this.current_title = data.profileable.current_title;
    },
    isStreamer(item){
      return item.profileable_type === 'STREAMER';
    },
    onBlockButtonClick(){
      this.showBlockConfirm = true
    },
    onCloseConfirmBlock(){
      this.showBlockConfirm = false
    },
    onCancelBlockButtonClick(){

    },
    async onBlock(profileable_id) {
      console.log(profileable_id)
      await addBlock(profileable_id);
      await this.init();
      this.showBlockConfirm = false
    },
    async onBlockCancel(profileable_id) {
      await removeBlock(profileable_id);
      await this.init();
    },
    avatarImageUrl(url){
      return getImageUrl(url)
    },
    setBackgroudColor() {
      const attribute_list = this.$store.state.system.enum_list.attribute_list;
      this.bk_url = getBackgroundColorImage(this.current_title, this.data.attribute, attribute_list);
    },
    checkBackgroundColor() {
      return this.bk_url.includes('white') ? '#666666' : 'white';
    }
  }
}
</script>