import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'

import { getToken } from '@/utils/auth'
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

const routes = [

  // Home
  {
    path: "/",
    name: "Home",
    redirect: { name: 'LoginIndex' }
  },

  // Login
  {
    path: "/login/index",
    name: "LoginIndex",
    component: () => import("@/views/Login/Index.vue")
  },

  // Top
  {
    path: "/top/index",
    name: "TopIndex",
    component: () => import("@/views/Top/Index.vue")
  },

  // Terms
  {
    path: "/terms/index",
    name: "TermsIndex",
    component: () => import("@/views/Terms/Index.vue")
  },

  {
    path: "/terms/TermFund",
    name: "TermsFund",
    component: () => import("@/views/Terms/Fund.vue")
  },
  // Select
  {
    path: "/select/index",
    name: "SelectIndex",
    component: () => import("@/views/Select/Index.vue")
  },

  // Profile
  {
    path: "/profile/index",
    name: "ProfileIndex",
    component: () => import("@/views/Profile/Index.vue")
  },
  
  // News
  {
    path: "/news/index",
    name: "NewsIndex",
    component: () => import("@/views/News/Index.vue")
  },

  // Help
  {
    path: "/help/index",
    name: "HelpIndex",
    component: () => import("@/views/Help/Index.vue")
  },

  // Question
  {
    path: "/question/index",
    name: "QuestionIndex",
    component: () => import("@/views/Question/Index.vue")
  },
  {
    path: "/question/now",
    name: "QuestionNow",
    component: () => import("@/views/Question/Now.vue")
  },
  {
    path: "/question/history",
    name: "QuestionHistory",
    component: () => import("@/views/Question/History.vue")
  },

  // Setting -> RoomName
  {
    path: "/setting/room-name/normal",
    name: "SettingRoomNameNormal",
    component: () => import("@/views/Setting/RoomName/Normal.vue")
  },

  // Setting -> RoomBanner
  {
    path: "/setting/room-banner/normal",
    name: "SettingRoomBannerNormal",
    component: () => import("@/views/Setting/RoomBanner/Normal.vue")
  },

  // Setting -> TwitterShare
  {
    path: "/setting/twitter-share/normal",
    name: "SettingTwitterShareNormal",
    component: () => import("@/views/Setting/TwitterShare/Normal.vue")
  },

  // Setting -> LiveSchedule
  {
    path: "/setting/live-schedule/normal",
    name: "SettingLiveScheduleNormal",
    component: () => import("@/views/Setting/LiveSchedule/Normal.vue")
  },
 
  // Setting -> Target
  {
    path: "/setting/target/new",
    name: "SettingTargetNew",
    component: () => import("@/views/Setting/Target/New.vue")
  },
  {
    path: "/setting/target/now",
    name: "SettingTargetNow",
    component: () => import("@/views/Setting/Target/Now.vue")
  },
  {
    path: "/setting/target/history/index",
    name: "SettingTargetHistoryIndex",
    component: () => import("@/views/Setting/Target/History/Index.vue")
  },
  {
    path: "/setting/target/history/detail/:target_id",
    name: "SettingTargetHistoryDetail",
    component: () => import("@/views/Setting/Target/History/Detail.vue")
  },
  {
    path: "/setting/target/history/gift/:target_id",
    name: "SettingTargetGift",
    component: () => import("@/views/Setting/Target/History/Gift.vue")
  },

  // Setting -> BlockList
  {
    path: "/setting/blockList/index",
    name: "SettingBlockList",
    component: () => import("@/views/Setting/BlockList/Index.vue")
  },

  // Setting -> EventManage
  {
    path: "/setting/eventmanage/index",
    name: "SettingEventManage",
    component: () => import("@/views/Setting/EventManage/Index.vue")
  },
  {
    path: "/setting/eventmanage/detail/:id",
    name: "SettingEventManageDetail",
    component: () => import("@/views/Setting/EventManage/Detail.vue")
  },
  
  // Setting -> SettingEventRecently
  {
    path: "/setting/eventrecently/index",
    name: "SettingEventRecently",
    component: () => import("@/views/Setting/EventRecently/Index.vue")
  },

  // Setting -> NGWord
  {
    path: "/setting/ngword/index",
    name: "SettingNGWord",
    component: () => import("@/views/Setting/NGWord/Index.vue")
  },

  // Setting -> ClubfunDetail
  {
    path: "/setting/clubfundetail/index/:liveable_id",
    name: "SettingClubfunDetailIndex",
    component: () => import("@/views/Setting/ClubfunDetail/Index.vue")
  },

  // Setting -> ScheduleConfirm
  {
    path: "/setting/schedule-confirm/index",
    name: "SettingScheduleConfirmIndex",
    component: () => import("@/views/Setting/ScheduleConfirm/Index.vue")
  },

  // Live
  {
    path: "/live/normal",
    name: "LiveNormal",
    component: () => import("@/views/Live/Normal.vue")
  },
  {
    path: "/live/event",
    name: "LiveEvent",
    component: () => import("@/views/Live/Event.vue")
  },
  {
    path: "/live/clubfun",
    name: "LiveClubfun",
    component: () => import("@/views/Live/ClubFun.vue")
  },
  {
    path: "/live/paid",
    name: "LivePaid",
    component: () => import("@/views/Live/Paid.vue")
  },
  

  { path: '*', redirect: { name: 'LoginIndex' }, hidden: true }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

const whiteList = [
  '/login/index', 
  "/terms/index",
]

let tokenCheckFlag = false;

router.beforeEach( async (to, from, next) => {

  // determine whether the user has logged in
  const token = getToken()
  
  if (token) {
    store.commit('user/SET_ID', `${jwt_decode(token).sub}`);
    store.commit('user/SET_MODULE', `${jwt_decode(token).module}`);

    // Refresh SystemInfo
    await store.dispatch('system/syncSystemInfo');
    await store.dispatch('system/syncSystemTime')
    
    // Refresh UserInfo On First Loading.
    if (!tokenCheckFlag) {
      await store.dispatch('user/refresh');
      tokenCheckFlag = true;
    }

    if (to.path === '/login/index') {
      // if is logged in, redirect to the home page
      next({ path: '/top/index' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login/index`)
    }
  }
})

export default router;
