<template lang="pug">
  .root(style="width: 100%;")
    div(v-if="this.showMessage.length !== 0" class="system-message") {{ this.showMessage | moment("YYYY/MM/DD HH:mm")  }} からメンテナンスです。ご注意ください。
</template>

<style src="./index.scss" lang="scss" scoped>
</style>

<script>
import moment from 'moment'

export default{
  data() {
    return {
      showMessage: ''
    }
  },
  async created() {
    await this.$store.dispatch('news/syncMaintenance');
    if(moment().add(24, 'h').format("YYYY-MM-DD HH:mm:ss") >= this.$store.state.news.maintenance){
        this.showMessage = this.$store.state.news.maintenance
    }
  }
}
</script>
