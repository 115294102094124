import { getEventNotice } from '../../api/system';

const state = {
  event_notice:''
}

const mutations = {
  'SET_EVENT_NOTICE':(state,eventNotice)=>{
    state.event_notice = eventNotice
  }
}

const actions = {
  async syncEventNotice({commit}){
    const { data } = await getEventNotice()
    if(data && data.event_note.content.describe){
      commit('SET_EVENT_NOTICE',data.event_note.content.describe);
    }
  }
}

export default {
  namespaced:true,
  state,
  mutations,
  actions
}