import request from '@/utils/request'

export function addBlock(profileable_id) {
    return request({
        url: `blocks/${profileable_id}`,
        method: 'put',
    })
}

export function getBlockList() {
    return request({
        url: `blocks`,
        method: 'get',
    })
}

export function removeBlock(profileable_id) {
    return request({
        url: `blocks/${profileable_id}`,
        method: 'delete',
    })
}

