import {padStart, floor, isNumber} from 'lodash';
import store from '@/store'
import moment from 'moment'

export function getLiveDurationStr(tsStart) {
  const secondDiff = new Date()/1000 - (isNumber(tsStart) ? tsStart : Date.parse(tsStart)/1000)
  if (secondDiff < 0) {
    return '0:00:00'
  }

  const newStr = `${padStart(floor(secondDiff/3600), 1, '0')}:${padStart(floor(secondDiff/60%60), 2, '0')}:${padStart(floor(secondDiff%60), 2, '0')}`;

  return !newStr.includes('NaN') ? newStr : '00:00:00';
}

export function getLiveDurationStrCheckFalse(ts) {
  if (!ts) {
    return '';
  }
  return getLiveDurationStr(ts);
}

export function getImageUrl(relativeUrl){
  return store.state.system.config.storage_addr_list.public + relativeUrl
}

export function isInvalidImageUrl(url){
  return (store.state.system.config.storage_addr_list.public === url || url == undefined || store.state.system.config.storage_addr_list.public + "undefined" === url)
}

export function serverMoment(){
  return moment(moment()+store.getters.time_diff)
}

export function isDev(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "dev" ){
    return true;
  }
  return false;
}

export function isStg(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "stg" ){
    return true;
  }
  return false;
}

export function isLocal(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "loc" ){
    return true;
  }
  return false;
}

export function isPrd(){
  const env = process.env.VUE_APP_PRJ_ENV
  if( env == "prd" ){
    return true;
  }
  return false;
}

export function countLength(str) { 
  var r = 0; 
  if(str) {
    for (var i = 0; i < str.length; i++) { 
      var c = str.charCodeAt(i); 
      if ( (c >= 0x0 && c < 0x81) || (c == 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) { 
          r += 1; 
      } else { 
          r += 2; 
      } 
    } 
  }
  return r; 
} 