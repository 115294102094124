import { getTargetListS2, getTargetRankList } from '@/api/target'
import _ from 'lodash'

const getDefaultState = () => {
    return {
        now: {
            id: 0,
            streamer_id: 0,
            name: "",
            target_point: 0,
            current_point: 0,
            content: "",
            banner_store_path: "",
            stage: "",
            is_posted: false,
            updated_at: ""
        },
        rankList:[],
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        const initState = getDefaultState();
        const keys = Object.keys(initState);
        for(let i = 0; i !== keys.length; i++) {
          if (_.isObject(initState[keys[i]])) {
            state[keys[i]] = Object.assign({}, initState[keys[i]]);
          } else {
            state[keys[i]] = initState[keys[i]];
          }
        }
    },

    SET_NOW_TARGAT: (state, value)=>{
        state.now = value
    },

    SET_VIEWER_RANK_LIST: (state, value)=> {
        state.rankList = value
    },
}

const actions = {
    async reset({ commit }) {
        commit('RESET_STATE');
    },

    async getNowTarget({ commit }) {
        const retNowTarget = await getTargetListS2();
        if (retNowTarget.data && retNowTarget.data.data[0]) {
            commit('SET_NOW_TARGAT', retNowTarget.data.data[0]);
        }else {
            commit('RESET_STATE')
        }
    },

    async getTargetViewerRankList({ commit }, target_id ) {
        const retRankList = await getTargetRankList(target_id);
        commit('SET_VIEWER_RANK_LIST', retRankList.data);  
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }